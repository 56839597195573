import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import Paginacao from "../../Components/Common/Paginacao";
import ModalMercadoPago from "./ModalMercadoPago";
import { useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const PedidosMercadoPago = () => {
  document.title = "Pedidos do Mercado Pago | HzPlay";
  const { user_id } = useParams();

  const [modalMercadoPago, setModalMercadoPago] = useState(false);
  const [qrData, setQrData] = useState({});

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [pedidos, setPedidos] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDePedidos, setTotalDePedidos] = useState(0);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [statusPedido, setStatusPedido] = useState("");

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchPedidos = useCallback(
    async (itensPorPagina, paginaAtual, statusPedido, user) => {
      setLoading(true);

      const userData = JSON.parse(localStorage.getItem("authUser"));

      const response = await apiClient.post(
        `painel-clientes/mercado-pago/buscar`,
        {
          itens_pagina: itensPorPagina,
          pagina: paginaAtual === "" ? paginaAtual : 1,
          status: statusPedido,
          username: userData.data.externo_login,
          password: userData.data.externo_senha,
        }
      );

      if (response.sucesso) {
        const dados = response.dados;
        setPedidos(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDePedidos(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }

      setLoading(false);
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchPedidos(itensPorPagina, paginaAtual, statusPedido);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, statusPedido, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Mercado Pago" pageTitle="Pedidos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={6}></Col>
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setStatusPedido(e.target.value);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="todos">Todos</option>
                  <option value="pendente">Pendente</option>
                  <option value="erro">Erro</option>
                  <option value="concluido">Concluído</option>
                  <option value="cancelado">Cancelado</option>
                </select>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Pedidos do Mercado Pago
                  </h4>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Data</th>
                              <th scope="col">Revendedor</th>
                              <th scope="col">Meses a Renovar</th>
                              <th scope="col">Preço</th>
                              <th scope="col">Status</th>
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pedidos &&
                              pedidos?.length !== 0 &&
                              pedidos.map((pedido, index) => {
                                //verifica se a data created é menor em 30 dias da data atual
                                const dataAtual = new Date();
                                const dataPedido = new Date(pedido.created);
                                const dataMenor30Dias =
                                  (dataAtual - dataPedido) /
                                    (1000 * 60 * 60 * 24) <
                                  30;
                                return (
                                  <tr key={index}>
                                    <td>{converterData(pedido.created)}</td>
                                    <td>{pedido.user.username}</td>
                                    <td className="text-info">
                                      {pedido.creditos}
                                    </td>
                                    <td>
                                      {pedido.valor.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })}
                                    </td>
                                    <td>
                                      {pedido.status === 0 ? (
                                        <span className="badge bg-secondary">
                                          Pendente
                                        </span>
                                      ) : pedido.status === 1 ? (
                                        <span className="badge bg-success">
                                          Concluído
                                        </span>
                                      ) : pedido.status === -1 ? (
                                        <span className="badge bg-danger">
                                          Erro
                                        </span>
                                      ) : pedido.status === 2 ? (
                                        <span className="badge bg-primary">
                                          Cancelado
                                        </span>
                                      ) : (
                                        <span className="badge bg-dark">
                                          Status Inválido
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-warning">
                                      {pedido.status === 1 &&
                                      dataMenor30Dias ? (
                                        <small className="text-muted">
                                          Seu plano já foi renovado para este
                                          mês. Saia do painel e entre novamente
                                          para visualizar.
                                        </small>
                                      ) : (
                                        pedido.status === 0 && (
                                          <>
                                            <Link
                                              to="#"
                                              className="btn btn-soft-warning fw-medium"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  pedido.qrcode
                                                );
                                                toast.success(
                                                  "QR Code copiado para a área de transferência."
                                                );
                                              }}
                                            >
                                              <FeatherIcon
                                                icon="copy"
                                                size="16"
                                                className="me-1"
                                              />
                                              PIX Copia e Cola
                                            </Link>
                                            <Link
                                              to="#"
                                              className="btn btn-soft-info fw-medium ms-2"
                                              onClick={() => {
                                                setQrData(pedido);
                                                setModalMercadoPago(
                                                  !modalMercadoPago
                                                );
                                              }}
                                            >
                                              Ver QR Code
                                            </Link>
                                          </>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && pedidos?.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum pedido encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDePedidos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDePedidos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDePedidos}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDePedidos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalMercadoPago
            modalMercadoPago={modalMercadoPago}
            setModalMercadoPago={setModalMercadoPago}
            qrDados={qrData}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default PedidosMercadoPago;
