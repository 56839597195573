import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatEmail,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react/";

const apiClient = new APIClient();

const MercadoPagoTeste = ({ parentDados, userDados }) => {
  const [meses, setMeses] = useState("");
  const [email, setEmail] = useState("");
  const [qrDados, setQrDados] = useState("");
  const [loading, setLoading] = useState(false);

  const comprarMeses = async (user) => {
    setLoading(true);

    const dados = {
      quantidade: meses,
      email: email === "" ? null : email,
      username: user.externo_login,
      password: user.externo_senha,
    };

    const response = await apiClient.post(
      `painel-clientes/mercado-pago/gerar-qr`,
      dados
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      setQrDados(response.dados?.qrcode);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (userDados && email === "" && userDados.email) {
      setEmail(formatEmail(userDados.email));
    }
  }, [userDados]);

  return (
    <Row className="mb-3">
      {qrDados && qrDados !== "" ? (
        <>
          <p className="text-muted">
            Faça o pagamento via PIX e aguarde ele ser confirmado para os
            créditos serem adicionados.
          </p>
          <div className="bg-soft-warning" style={{ textAlign: "center" }}>
            <QRCode
              value={qrDados}
              size={256}
              className="p-3"
              style={{ background: "white", borderRadius: "10px" }}
            />
            <p className="text-warning mt-3">
              Créditos: {meses} | Meses: {meses} | Preço: R${" "}
              {parentDados?.valor_usuario * meses}
            </p>
          </div>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Button
              color="warning"
              className="btn btn-soft-warning"
              onClick={() => {
                navigator.clipboard.writeText(qrDados);
                toast.success("QR Code copiado para a área de transferência.");
              }}
            >
              <FeatherIcon icon="copy" size="16" /> PIX Copia e Cola
            </Button>
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </>
      ) : (
        <div className="mt-4">
          <Row>
            <Col xs={12}>
              <Label for="email" className="form-label">
                E-mail <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(formatEmail(e.target.value))}
              />
            </Col>
            <Col xs={12}>
              <Label for="meses" className="form-label mt-3">
                Créditos (quantidade de meses){" "}
                <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                id="meses"
                name="meses"
                className="form-control"
                value={meses}
                onChange={(e) => setMeses(e.target.value.replace(/\D/g, ""))}
              />
              <small className="text-muted">
                Preço: R${" "}
                {(
                  (parentDados?.valor_usuario || 0) *
                  parseInt(meses === "" ? 0 : meses)
                ).toFixed(2)}
              </small>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Button
              color="warning"
              className="btn btn-warning"
              onClick={() => comprarMeses(userDados)}
              disabled={loading}
            >
              Gerar QR Code
            </Button>
          </div>
        </div>
      )}
    </Row>
  );
};

export default MercadoPagoTeste;
