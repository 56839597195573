import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import UserConfig from "./UserConfig";
import MeiosPagamento from "./MeiosPagamento";

const UserProfile = () => {
  document.title = "Perfil de Usuário | HzPlay";
  const [userDados, setUserDados] = useState({});

  const getData = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <FeatherIcon
                        icon="user"
                        width="13"
                        className="avatar-md rounded-circle img-thumbnail text-warning"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userDados?.nome}</h5>
                        <p className="mb-1">Email: {userDados?.email}</p>
                        <p className="mb-0">Username: {userDados?.username}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <UserConfig />
          <MeiosPagamento userDados={userDados} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
