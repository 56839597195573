// Função para verificar o último horário de login
export const checkLastLogin = async () => {
  const lastLoginTimestamp = localStorage.getItem("lastLogin");
  if (lastLoginTimestamp) {
    const lastLoginTime = new Date(parseInt(lastLoginTimestamp));
    const currentTime = new Date();
    const timeDiffInHours = (currentTime - lastLoginTime) / (1000 * 60 * 60);

    if (timeDiffInHours >= 1) {
      localStorage.removeItem("lastLogin");
      localStorage.removeItem("authUser");
      window.location.reload();
    }
  }
};

// Função para limpar html e truncar mensagem
export const limparMensagem = (input) => {
  const semTagsHtml = input?.replace(/<[^>]*>/g, "");
  const truncado =
    semTagsHtml?.length > 55 ? semTagsHtml?.slice(0, 55) + "..." : semTagsHtml;

  return truncado;
};

export const trataCaminho = (usuario, caminho) => {
  const partes = caminho.split(" > ");
  const indiceUsuario = partes.indexOf(usuario);

  if (indiceUsuario === -1) {
    return caminho;
  }

  if (indiceUsuario === partes.length - 1) {
    return "";
  }

  return partes.slice(indiceUsuario + 1).join(" > ");
};

export const generateRandomString = () => {
  const letras = "abcdefghijklmnopqrstuvwxyz";
  const numeros = "0123456789";

  let resultado = "";
  for (let i = 0; i < 4; i++) {
    resultado += letras.charAt(Math.floor(Math.random() * letras.length));
  }

  for (let i = 0; i < 4; i++) {
    resultado += numeros.charAt(Math.floor(Math.random() * numeros.length));
  }

  return resultado;
};

export const converterData = (dataISO, minutoSegundo = true) => {
  if (dataISO) {
    const ano = dataISO.substring(0, 4);
    const mes = dataISO.substring(5, 7);
    const dia = dataISO.substring(8, 10);
    const hora = dataISO.substring(11, 13);
    const minuto = dataISO.substring(14, 16);

    return minutoSegundo
      ? `${dia}/${mes}/${ano} ${hora}:${minuto}`
      : `${dia}/${mes}/${ano}`;
  }
  return "";
};

export const formatEmail = (value) => {
  value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

  const atSymbolCount = value.split("@").length - 1;
  if (atSymbolCount > 1) {
    const index = value.lastIndexOf("@");
    value = value.slice(0, index) + value.slice(index + 1);
  }

  return value;
};