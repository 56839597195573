import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { converterData } from "../../Components/FunctionsRepository";
import MercadoPagoTeste from "./MercadoPagoTeste";

const TestesAutomaticos = (props) => {
  const { revendedor_hash } = useParams();

  const [revendedorId, setRevendedorId] = useState(0);
  const [testeDados, setTesteDados] = useState(null);
  const [parentDados, setParentDados] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [enviado, setEnviado] = useState(false);
  const [pacote, setPacote] = useState("completo");
  const [horas, setHoras] = useState(0);
  const [sistema, setSistema] = useState(0);
  const [mensagemTipo, setMensagemTipo] = useState("success");
  const [mensagem, setMensagem] = useState("");

  const cleanUp = async () => {
    setLoading(true);
    setEmail("");
    setEnviado(false);
    setPacote("completo");
    setMensagem("");
    setMensagemTipo("success");
    setLoading(false);
  };

  const enviar = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teste-automatico`,
        {
          revendedor_id: revendedorId,
          email,
          painel_tipo: sistema,
          horas,
          pacote,
        }
      );
      setEnviado(true);
      setMensagemTipo("success");
      const { mensagem, dados } = response.data;
      setMensagem(mensagem);
      if (dados) {
        setTesteDados(dados.teste_dados);
        setParentDados(dados.parent_dados);
      }
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      console.error(error);
      setEnviado(true);
      setMensagemTipo("danger");
      setMensagem(mensagemErro);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const decodedHash = atob(revendedor_hash);
      const [revendedor_id, sistema_id, horasTeste] = decodedHash.split(":");
      setRevendedorId(revendedor_id);
      setSistema(sistema_id);
      setHoras(horasTeste);
      setLoading(false);
    };
    fetch();
  }, []);

  document.title = "Requisição de Teste | Clientes HzPlay";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            {testeDados && parentDados ? (
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4 card-bg-fill">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-secondary">Ativar como Cliente</h5>
                        <small className="text-info">
                          O seu teste já é existente, com vencimento em:{" "}
                          <b className="text-warning">{converterData(testeDados.expiracao)}</b>
                          <br /><br />
                          Caso deseje ser ativo como cliente, digite a
                          quantidade de meses que deseja adquirir e pague via
                          PIX pelo <b>Mercado Pago</b>.
                        </small>
                      </div>
                      <div className="p-2 mt-4">
                        <MercadoPagoTeste
                          parentDados={parentDados}
                          userDados={testeDados}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4 card-bg-fill">
                    {enviado ? (
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className={`text-${mensagemTipo}`}>{mensagem}</h5>
                        </div>
                        {mensagemTipo !== "success" && (
                          <div className="p-2 mt-4">
                            <Row>
                              <Button
                                color="danger"
                                disabled={loading}
                                className="btn btn-primary w-100"
                                onClick={(e) => cleanUp()}
                              >
                                Tentar de Novo
                              </Button>
                            </Row>
                          </div>
                        )}
                      </CardBody>
                    ) : (
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-secondary">Requisitar Teste</h5>
                        </div>
                        <div className="p-2 mt-4">
                          <Row className="mb-3">
                            <Label htmlFor="email" className="form-label">
                              E-mail
                            </Label>
                            <Input
                              className="form-control"
                              id="email"
                              placeholder="Digite o email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <small className="text-muted">
                              Use um e-mail válido para receber as informações
                              de acesso.
                            </small>
                          </Row>
                          <Row className="mb-3">
                            <Label htmlFor="pacote" className="form-label">
                              Pacote
                            </Label>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                name="pacote"
                                id="pacote"
                                value="completo"
                                checked={pacote === "completo"}
                                onChange={(e) => setPacote(e.target.value)}
                              />
                              <Label htmlFor="pacote" className="form-label">
                                Completo
                              </Label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                name="pacote"
                                id="pacote"
                                value="sem-adultos"
                                checked={pacote === "sem-adultos"}
                                onChange={(e) => setPacote(e.target.value)}
                              />
                              <Label htmlFor="pacote" className="form-label">
                                Sem adultos
                              </Label>
                            </div>
                          </Row>
                          <Row className="mt-4">
                            <Button
                              color="secondary"
                              disabled={loading}
                              className="btn btn-primary w-100"
                              onClick={(e) => enviar(e)}
                            >
                              {loading ? (
                                <Spinner size="sm" className="me-2" />
                              ) : null}
                              Requisitar
                            </Button>
                          </Row>
                        </div>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default TestesAutomaticos;
