import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import PedidosMercadoPago from "../pages/PedidosMercadoPago";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import TestesAutomaticos from "../pages/Authentication/TestesAutomaticos";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import { APIClient } from "../helpers/api_helper";
const apiClient = new APIClient();
const userData = apiClient.getLoggedinUser();

const authProtectedRoutes = [
  { path: "/principal", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/pedidos-mercado-pago", component: <PedidosMercadoPago /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/principal" />,
  },
  { path: "*", component: <Basic404 />},
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },,
  {
    path: "/testes/:revendedor_hash",
    component: <TestesAutomaticos />,
  },
];

export { authProtectedRoutes, publicRoutes };
